import React from 'react';
import styled from 'styled-components';
import { aboutTopImg, aboutTopImgMobile } from 'components/utils/getImages';

const WideImageWrapper = styled.div`
  width: 100%;
  margin: 200px 0 0;
  padding: 0 90px;
  @media (min-width: 320px) and (max-width: 768px) {
    margin: 120px 0 0;
    padding: 0 20px;
  }
`;

const WideImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
`;

const WideImage = styled.div`
  position: absolute;
  display: inline-block;
  background-image: url(${aboutTopImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  inset: 0;
  @media (min-width: 320px) and (max-width: 768px) {
    background-image: url(${aboutTopImgMobile});
  }
`;

function WhatAboutDoImage() {
  return (
    <WideImageWrapper>
      <WideImageContainer>
        <WideImage />
      </WideImageContainer>
    </WideImageWrapper>
  );
}

export default WhatAboutDoImage;
