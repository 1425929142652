import {
  profileBHG,
  profileHJH,
  profileGJY,
  profileYHS,
  profilePCJ,
  ceoImgMain,
  profileKKW,
  profileABU,
  profileBCW,
} from 'components/utils/getImages';

export const aboutDos = [
  {
    id: 1,
    number: '01',
    title: '도전하고',
    firstsubtitle: '혁신적 발상',
    firstsubdes: '시장에 던질 새로운 화두를 늘 고민합니다.',
    secondsubtitle: '다양한 시도',
    secondsubdes: '머뭇거리지 않고 다양한 방식으로 시도합니다.',
  },
  {
    id: 2,
    number: '02',
    title: '실행하고',
    firstsubtitle: '추진력',
    firstsubdes: '목표한 바를 향해 멈춤 없이 나아갑니다.',
    secondsubtitle: '지속적 학습',
    secondsubdes: '장애물들을 넘어서기 위해 늘 고민합니다.',
  },
  {
    id: 3,
    number: '03',
    title: '인내합니다',
    firstsubtitle: '섬세함',
    firstsubdes: '고객의 입장에서 고민하고 이를 적용합니다.',
    secondsubtitle: '수용의 자세',
    secondsubdes: '실패를 두려워하지 않고 재도전을 위한 근력을 쌓습니다.',
  },
];

export const profileData = [
  {
    src: profileGJY,
    name: 'Jeayeon Ko',
    stack: 'Management Assistant',
    des: '안녕하세요! 석세스모드 경영지원팀 고제연 매니저입니다. 직원분들의 건강하고 행복한 회사 생활과 업무 환경을 위하여 항상 관심을 가지고 귀를 기울이고 있겠습니다!',
  },
  {
    src: profileBHG,
    name: 'Hyungon Bae',
    stack: 'Sales Manager',
    des: '석세스모드에서 영업 포지션을 담당하고 있습니다. 항상 사용자 입장에서 필요한 게 무엇일까 생각하고 행동으로 실천하는 사람이 되겠습니다!',
  },
  {
    src: profileYHS,
    name: 'Hyoseong Yoon',
    stack: 'Marketer',
    des: '1일 1성장, 마케터 윤효성입니다. 주변 지인들도 알 수 있는 브랜드가 될 수 있도록 노력하겠습니다.',
  },
  {
    src: profileKKW,
    name: 'Kyuwon Kim',
    stack: 'UX UI Designer',
    des: '명확하게 일하고 명랑하게 소통하는 프로덕트 디자이너 김규원입니다.',
  },
  {
    src: profileHJH,
    name: 'Jinhee Ha',
    stack: 'Front-end Developer',
    des: '함께 일하는 직무인 만큼 팀의 구성원으로서 시너지를 낼 수 있는 사람, 그리고 개발자로서 신뢰할 수 있는 사람이 되고 싶습니다. 항상 주도적으로 행동하고 배우고 성장해 나가겠습니다.',
  },
  {
    src: profilePCJ,
    name: 'Changjin Park',
    stack: 'Front-end Developer',
    des: '점진적인 발전을 위해 멈추지 않고 달리는 프론트엔드 개발자입니다. 혼자 가면 빨리 갈 수 있지만 함께 가면 멀리 갈 수 있다는 마음가짐으로 함께 성장하는 개발자가 되겠습니다.',
  },
  {
    src: profileABU,
    name: 'Byunguk Ahn',
    stack: 'Back-end Developer',
    des: '안녕하세요! 백엔드 개발자 안병욱입니다! 오늘도 제공하는 서비스가 왜 되는지 항상 의심하며 더 나은 서비스를 제공할거에요. 열린 결말처럼 답 없는 토론 좋아합니다. 언제든 말걸어주세요 업무 시간 내에서만요!',
  },
  {
    src: profileBCW,
    name: 'Changwoo Byun',
    stack: 'Back-end Developer',
    des: '백엔드 개발자 변창우입니다. 모두가 만족하는 서비스를 제공할 수 있도록 끊임없이 고민하고 실행하겠습니다.',
  },
];

export const historyStacks = [
  {
    year: 2021,
    stacks: [
      '한국관광공사 관광분야 스타트업 액셀러레이팅 데모데이 수상',
      '대구시, 대구관광재단과 동성로 일대 사업 구축 협약 체결',
      '기업부설 연구소 개설',
      '전략형 R&D 기업 선정',
      '안양시 유망창업기업 BLUE 100 선정',
    ],
  },
  {
    year: 2020,
    stacks: [
      '소상공인 스마트상점 기술개발 공급기업',
      '경기도 환급창구운영사업자 지정',
      'NH Digital Challenge 3+ 참여',
      '한국관광공사 관광벤처 선정',
      '기술보증기금 벤처기업 인증',
    ],
  },
  {
    year: 2019,
    stacks: [
      '중국 산동성 한중혁신대회 우수상',
      '사후면세판매장 개설',
      '한국인테넛진흥원 위치기반서비스 공모 우수상',
      '한국관광공사 스마트관광 APP 공모 최우수상',
      '중소기업벤처부 청년창업사관학교 사업 참여',
    ],
  },
  {
    year: 2018,
    stacks: ['전국청년창업경진대회 우수상', '석세스모드 설립'],
  },
];

export const ceoData = [
  {
    src: ceoImgMain,
    name: 'Byeongcheon Ju',
    stack: 'CEO',
    des: '고려대학교 전기전자전파공학부 졸업 후 LG전자에서 사회생활을 시작하였습니다. IT분야 특허소송 업무를 수행하며 기술기획 분야에 관심을 가지게 되었고, 이후 롯데백화점 미래전략본부 옴니T/F를 거쳐 2018년 석세스모드를 창업 이끌고 있습니다.',
  },
];
