import styled from 'styled-components';
import { sImg1, sImg2, sImg3, sWideImg } from 'components/utils/getImages';

const ImageContainerWrapper = styled.div`
  margin-top: 70px;
  margin-bottom: 286px;
  width: 100%;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 320px;
  }
`;

const WideImage = styled.img`
  width: 100%;
  height: 514px;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 320px;
    height: 180px;
  }
`;

const SquareImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  width: 100%;
  height: auto;
  @media (min-width: 320px) and (max-width: 768px) {
    margin-top: 10px;
  }
`;

const SquareImage = styled.img`
  width: 31.5%;
  height: 378px;
  aspect-ratio: 1;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

function CultureImageSection() {
  return (
    <ImageContainerWrapper>
      <WideImage src={sWideImg} />
      <SquareImageWrapper>
        <SquareImage src={sImg1} />
        <SquareImage src={sImg2} />
        <SquareImage src={sImg3} />
      </SquareImageWrapper>
    </ImageContainerWrapper>
  );
}

export default CultureImageSection;
