import styled from 'styled-components';
import { ceoImgMainNew } from 'components/utils/getImages';

const WhatAboutMemberWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 320px) and (max-width: 768px) {
    flex-direction: column;
    align-items: baseline;
    width: 90vw;
  }
`;
const TeamImage = styled.img`
  width: 276px;
  height: auto;
  background-color: gray;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 152px;
    height: auto;
  }
`;
const TeamStack = styled.div`
  display: flex;
  flex-direction: column;
  width: 690px;
  margin-left: 85px;

  @media (min-width: 320px) and (max-width: 768px) {
    margin: 0;
    width: 100%;
  }
`;
const MemberName = styled.p`
  font-size: 32px;
  font-weight: 500;
  @media (min-width: 320px) and (max-width: 768px) {
    margin-top: 20px;
    font-size: 20px;
  }
`;
const MemberStack = styled.p`
  font-size: 14px;
  margin: 11px 0 20px 0;
`;
const MemberDes = styled.p`
  font-size: 14px;
  line-height: 24px;
`;

function CeoSection() {
  return (
    <WhatAboutMemberWrapper>
      <TeamImage src={ceoImgMainNew} />
      <TeamStack>
        <MemberName>Byeongcheon Ju</MemberName>
        <MemberStack>CEO</MemberStack>
        <MemberDes>
          고려대학교 전기전자전파공학부 졸업 후 LG전자에서 사회생활을
          시작하였습니다. <br /> IT분야 특허소송 업무를 수행하며 기술기획 분야에
          관심을 가지게 되었고, 이후 롯데백화점 미래전략본부 옴니T/F를 거쳐
          2018년 석세스모드를 창업 이끌고 있습니다.
        </MemberDes>
      </TeamStack>
    </WhatAboutMemberWrapper>
  );
}

export default CeoSection;
