import React from "react";
import styled from "styled-components";
import MAIN_VOD_URL from "../../assets/video_main.mp4";
import "aos/dist/aos.css";

const MainWrapper = styled.div``;

const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    max-width: 320px;
    height: auto;
    overflow: hidden;
  }
`;
const VideSource = styled.video`
  width: 100%;
  height: 100%;
  @media (min-width: 320px) and (max-width: 768px) {
    min-width: 932px;
  }
`;
const MainVideo = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 60px;
  margin-top: 82px;
  position: static;
  @media (min-width: 320px) and (max-width: 768px) {
    margin-top: 80px;
    height: 524px;
    width: 100%;
    max-width: 320px;
  }
`;
const MainTitle = styled.p`
  font-size: 68px;
  font-weight: 500;
  text-align: center;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  margin: 0;
  z-index: 100;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 32px;
    width: 220px;
    padding: 0;
    top: 200px;
  }
`;

const MainArticle = () => {
  return (
    <MainWrapper>
      <MainVideo>
        <VideoWrap>
          <MainTitle>Challenge, Execution, Persevere, successmode</MainTitle>
          <VideSource
            src={MAIN_VOD_URL}
            type="video/mp4"
            autoPlay
            muted
            loop
            playsInline
          />
        </VideoWrap>
      </MainVideo>
    </MainWrapper>
  );
};

export { MainArticle };
