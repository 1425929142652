import styled from 'styled-components';
import { cultureMainImg } from 'components/utils/getImages';

const ContentsImage = styled.img`
  width: 100%;
  height: 1292px;
  margin-top: 166px;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 320px;
    height: auto;
  }
`;

const SectionTitleWrapper = styled.div`
  flex-direction: column;
  margin-top: 90px;
  @media (min-width: 320px) and (max-width: 768px) {
  }
`;

const SectionTitleText = styled.div`
  font-size: 48px;
  font-weight: 400;
  line-height: 72px;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 28px;
    line-height: 40px;
  }
`;

function CultureTopSection() {
  return (
    <>
      <ContentsImage src={cultureMainImg} />
      <SectionTitleWrapper>
        <SectionTitleText>We are proud of you</SectionTitleText>
        <SectionTitleText>‘우리는 네가 자랑스러워’</SectionTitleText>
      </SectionTitleWrapper>
    </>
  );
}

export default CultureTopSection;
