import styled from 'styled-components';
import CeoSection from 'components/CeoContent/CeoSection';

const WhatAboutMemberWrapper = styled.div`
  margin: 300px 0 0 0;
  min-height: calc(100vh - 438px);
  @media (min-width: 320px) and (max-width: 768px) {
    margin: 100px 20px;
    width: 320px;
  }
`;

function CeoContent() {
  return (
    <WhatAboutMemberWrapper>
      <CeoSection />
    </WhatAboutMemberWrapper>
  );
}

export default CeoContent;
