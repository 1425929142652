import { tmarket, ktp, tpay } from "components/utils/getImages";

export const workDos = [
  {
    id: 1,
    src: tmarket,
    title: "T.market",
    subs: "고객들은 온라인을 통해 국내 상품들을 사전에 기존보다 저렴하게 구매하고 호텔 또는 공항에서 원할 때 상품을 수령할 수 있습니다.",
  },
  {
    id: 2,
    src: ktp,
    title: "KTP",
    subs: "Tablet 기반 간편결제 기기를 통해 더 저렴하고 더 쉽게 사용가능한 즉시환급 솔루션을 사후면세판매장에 공급합니다.",
  },
  {
    id: 3,
    src: tpay,
    title: "T.cash",
    subs: "선불 카드 기반 결제 수단 TOUR CASH를 통해 고객들은 어느 매장에서든 면세 쇼핑의 혜택을 누릴 수 있습니다.",
  },
];
