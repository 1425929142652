import React from 'react';
import styled from 'styled-components';
import { kakaoLinkImg, contactMapImg } from 'components/utils/getImages';

const KakaoLinkImg = styled.img`
  max-width: 184px;
  height: 46px;
  transform: translateY(-100%);
  @media (min-width: 320px) and (max-width: 768px) {
    transform: translateY(-150%);
    width: 120px;
    height: 28px;
  }
`;

const KakaoLink = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  transform: translateY(-100%);
  width: 90vw;
`;

const ContactMapWrap = styled.div`
  position: relative;
  width: 90vw;
  height: 100%;
  margin-bottom: 240px;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    margin-bottom: 0;
  }
`;
const ContactMapBack = styled.img`
  width: 90vw;
  height: auto;
  @media (min-width: 320px) and (max-width: 768px) {
    width: auto;
    height: 524px;
    overflow: hidden;
    transform: translateX(-30%);
  }
`;
const MapBackWrap = styled.div`
  width: 90vw;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 90vw;
    height: 524px;
    overflow: hidden;
  }
`;

const MapPin = styled.div`
  top: 0;
  border: 1px solid white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 4;
  margin: 0;
  transform: translate(1.8vw, -5vh);
  animation: marker 2s ease-in infinite;
  /* transform: translate(-3vw, 23vh); */
  &:hover {
    width: 66px;
    height: 66px;
    opacity: 0.1;
    transition: 0.3s ease-in;
  }
  @media (min-width: 320px) and (max-width: 768px) {
    width: 30px;
    height: 30px;
    border: 1px solid white;
    transform: translate(-5vw, -3vh);
    &:hover {
      width: 35px;
      height: 35px;
      opacity: 0.1;
      transition: 0.3s ease-in;
    }
  }
`;
const MapPinMarker = styled.div`
  background-color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(1vw, 1vw);
  @media (min-width: 320px) and (max-width: 768px) {
    width: 4px;
    height: 4px;
    transform: translate(3vw, 3vw);
  }
`;
const Pin = styled.div`
  position: absolute;
  width: 320px;
  top: 47%;
  left: 39%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100px;
    left: 40%;
  }
`;

function ContactMap() {
  return (
    <ContactMapWrap>
      <MapBackWrap>
        <ContactMapBack src={contactMapImg}></ContactMapBack>
      </MapBackWrap>
      <KakaoLink href="https://place.map.kakao.com/1855041395" target="_blank">
        <KakaoLinkImg src={kakaoLinkImg} />
      </KakaoLink>
      <Pin>
        <MapPin>
          <MapPinMarker />
        </MapPin>
      </Pin>
    </ContactMapWrap>
  );
}

export default ContactMap;
