import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

export const GlobalStyle = createGlobalStyle`
    ${normalize};

    * {
        box-sizing: border-box;
    }
    body {
        font-family: 'Noto Sans KR', sans-serif;
        font-weight: 300;
        padding: 0;
        margin: 0;
    }
    ul {
        list-style: none;
        padding: 0;
    }
    a {
        text-decoration: none;
        color: #000;
    }
`;
