import styled from 'styled-components';
import MemberSection from 'components/MemberContent/MemberSection';

const WhatAboutMemberWrapper = styled.div`
  margin: 300px 0 0;
  min-height: calc(100vh - 438px);
  @media (min-width: 320px) and (max-width: 768px) {
    /* margin: 191px 20px; */
    margin: 100px 20px;
    width: 320px;
  }
`;

function MemberContent() {
  return (
    <WhatAboutMemberWrapper>
      <MemberSection />
    </WhatAboutMemberWrapper>
  );
}

export default MemberContent;
