import styled from 'styled-components';
import HeaderNav from 'components/_common/Header';
import Footer from 'components/_common/Footer';
import CultureContent from 'components/CultureContent';

const ScreenWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

function Culture() {
  return (
    <>
      <ScreenWrapper>
        <HeaderNav />
        <CultureContent />
      </ScreenWrapper>
      <Footer />
    </>
  );
}

export default Culture;
