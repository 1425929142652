export const cultureArticleData = [
  {
    id: 1,
    number: '01',
    title: 'Refresh Summer 휴가 및 자율 연차',
    contents:
      '열심히 일한 당신 떠나라! 날짜와 기간, 사유에 구애 받지 않고\n원하는 대로 휴가를 쓸 수 있는 진정한 자율 휴가제를 실시합니다.',
    isFloat: 'left',
  },
  {
    id: 2,
    number: '02',
    title: '설, 추석 명절선물 지급',
    contents:
      '가족과 함께 풍족한 명절 연휴 보내시라고\n명절맞이 기프트를 지급해 드립니다!',
    isFloat: 'right',
  },
  {
    id: 3,
    number: '03',
    title: 'Lunch Time 70분 + 식대 지급',
    contents:
      '여유롭게 1시간 10분 동안 점심 식사를 즐길 수 있어요.\n식사 후 휴게 공간에서 커피 한잔의 여유까지!',
    isFloat: 'left',
  },
  {
    id: 4,
    number: '04',
    title: '업무시간 음악감상',
    contents:
      '일에만 집중할 수 있도록 업무시간 내 이어폰 착용 가능!\n방해 안 받고 열일할 수 있는 분위기를 보장해드립니다. ',
    isFloat: 'right',
  },
  {
    id: 5,
    number: '05',
    title: '최상의 근무 환경',
    contents:
      '결국 책상에 앉아 일할 때 행복한 것이 진짜 복지라는 생각으로,\n사무용품, 가습기, 넓은 튼튼한 책상 등 편한 근무 환경 구축을 위해 힘쓰고 있습니다.',
    isFloat: 'left',
  },
  {
    id: 6,
    number: '06',
    title: '도서 지원',
    contents:
      '업무용 도서는 내돈내산 하지마세요.\n필요할 때 언제든지 신청할 수 있어 업무 향상 및 자기계발이 가능합니다. ',
    isFloat: 'right',
  },
  {
    id: 7,
    number: '07',
    title: '수평적인 팀 문화',
    contents:
      '모자, 슬리퍼 등 자율복장은 기본!\n자유롭고 수평적인 분위기 기대하셔도 좋습니다.',
    isFloat: 'left',
  },
  {
    id: 8,
    number: '08',
    title: '스타트업 필수템 iMac과 MacBook',
    contents:
      '아이맥과 맥북을 포함한 300만원 상당의 최신 장비를 지원해 드립니다.\n장비 욕심 부릴 수 있는 절호의 찬스!',
    isFloat: 'right',
  },
];
