import React, { useEffect } from 'react';
import styled from 'styled-components';
import ContactItem from 'components/ContactContent/ContactItem';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { contactBackImg } from 'components/utils/getImages';

const ContactWrapper = styled.div``;

const WhatContact = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

const ContactBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
  background-image: url(${contactBackImg});
  background-size: 130%;
  background-repeat: no-repeat;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 90vw;
    height: auto;
    background-size: 800%;
  }
`;

const ContactWrap = styled.div`
  margin: 180px 0 100px 265px;
  @media (min-width: 320px) and (max-width: 768px) {
    margin: 180px 20px 73px 20px;
    width: 250px;
  }
`;

const ContactTitle = styled.p`
  margin-bottom: 15px;
  font-size: 48px;
  font-weight: 500;
  color: #f1f1f1;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 0;
  }
`;

const ContactSub = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #f1f1f1;
  margin: 0 265px 150px 265px;
  @media (min-width: 320px) and (max-width: 768px) {
    flex-direction: column;
    margin: 0 20px 0 20px;
  }
`;

const ContactSubs = styled.div`
  margin-bottom: 240px;
  @media (min-width: 320px) and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 64px;
  }
`;

const ContactSubDes = styled.p`
  font-size: 16px;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
    width: 280px;
  }
`;

function ContactArticle() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    AOS.refresh();
  }, []);

  return (
    <ContactWrapper>
      <WhatContact>
        <ContactBackground>
          <ContactWrap data-aos={'fade-down'}>
            <ContactTitle>Hello.</ContactTitle>
            <ContactTitle>We are always open-minded and</ContactTitle>
            <ContactTitle>ready to listen to the other person.</ContactTitle>
          </ContactWrap>
          <ContactSub>
            <ContactSubs data-aos={'fade-right'}>
              <ContactTitle>Contact</ContactTitle>
              <ContactSubDes>
                Please contact below email address and
              </ContactSubDes>
              <ContactSubDes>
                contact number if you like to continue the project
              </ContactSubDes>
              <ContactSubDes>with SUCCESS MODE.</ContactSubDes>
            </ContactSubs>
            <ContactItem
              city='Seoul'
              address='8F 11-41, Simin-daero 327beon-gil, Dongan-gu, Anyang-si, Gyeonggi-do, Republic of Korea'
              titletel='Tel'
              tel='+82. (0)2. 6275. 8011'
              email='postmaster@successmode.co.kr'
            />
            <ContactItem
              city='China'
              address='山东省威海市经济技术开发区香港路18-1号智慧
                    大厦209'
              email='postmaster@successmode.co.kr'
            />
          </ContactSub>
        </ContactBackground>
      </WhatContact>
    </ContactWrapper>
  );
}

export default ContactArticle;
