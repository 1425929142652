import styled from 'styled-components';
import AboutDoSection from 'components/AboutContent/AboutDoSection';
import AboutArticleSection from 'components/AboutContent/AboutArticleSection';
import AboutDoImgSection from 'components/AboutContent/AboutDoImgSection';
import AboutHistorySection from 'components/AboutContent/AboutHistorySection';
import AboutTeamSection from 'components/AboutContent/AboutTeamSection';
import AboutServiceSection from 'components/AboutContent/AboutServiceSection';
import { DummyAbout } from 'constants/aboutDataClasses';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 360px;
  height: 100%;
`;

const WhatAboutContentWrapper = styled.div`
  width: 100vw;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 100vw;
  }
`;

const WhatAboutArticleWrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 266px;
  margin: 0 360px;
  @media (min-width: 320px) and (max-width: 768px) {
    margin: 0px;
    font-size: 28px;
    padding-top: 177px;
  }
`;

const WhatAboutDoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 90px;
  @media (min-width: 320px) and (max-width: 768px) {
    margin: 67px 20px 0 20px;
  }
`;

const Aboutcontent = () => {
  const { aboutDos } = new DummyAbout().read();

  return (
    <Wrap>
      <WhatAboutContentWrapper>
        <WhatAboutArticleWrapper>
          <AboutArticleSection />
          <WhatAboutDoWrapper>
            {aboutDos.map((aboutDo) => (
              <AboutDoSection key={aboutDo.id} {...aboutDo} />
            ))}
          </WhatAboutDoWrapper>
        </WhatAboutArticleWrapper>
        <AboutDoImgSection />
        <AboutTeamSection />
        <AboutHistorySection />
        <AboutServiceSection />
      </WhatAboutContentWrapper>
    </Wrap>
  );
};

export default Aboutcontent;
