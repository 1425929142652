import { workDos } from './_dummy/dummyWork';

export class DummyWork {
  constructor() {
    this.workDos = workDos;
  }

  read() {
    return this.workDos;
  }
}
