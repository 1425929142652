import styled from 'styled-components';
import HeaderNav from 'components/_common/Header';
import Footer from 'components/_common/Footer';
import WorkContent from 'components/WorkContent';

const ScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const WorkPresenter = () => {
  return (
    <>
      <ScreenWrapper>
        <HeaderNav />
        <WorkContent />
      </ScreenWrapper>
      <Footer />
    </>
  );
};

export default WorkPresenter;
