import { cultureArticleData } from './_dummy/dummyCulture';

export class DummyCulture {
  constructor() {
    this.articles = cultureArticleData;
  }

  read() {
    return this.articles;
  }
}
