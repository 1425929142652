import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import About from 'pages/About';
import Culture from 'pages/Culture';
import Member from 'pages/Member';
import Main from 'pages/Main';
import Contact from 'pages/Contacts';
import Work from 'pages/Work';
import Ceo from 'pages/Ceo';
import { routes } from 'routes';
import ScrollToTop from 'components/_common/ScrollToTop';

function RouterContainer() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path={routes.main}>
          <Main />
        </Route>
        <Route path={routes.about}>
          <About />
        </Route>
        <Route path={routes.member}>
          <Member />
        </Route>
        <Route path={routes.work}>
          <Work />
        </Route>
        <Route path={routes.culture}>
          <Culture />
        </Route>
        <Route path={routes.contact}>
          <Contact />
        </Route>
        <Route path={routes.ceo}>
          <Ceo />
        </Route>
      </Switch>
    </Router>
  );
}

export default RouterContainer;
