import styled from 'styled-components';

const WhatAboutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: 320px) and (max-width: 768px) {
    margin: 0 20px;
  }
`;

const WhatAboutTitle = styled.h2`
  font-size: 48px;
  font-weight: 400;
  max-width: 890px;
  line-height: 72px;
  word-break: keep-all;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 28px;
    margin-right: 18%;
    line-height: 42px;
  }
`;

const WhatAboutText = styled.p`
  font-size: 16px;
  margin-top: 30px;
  max-width: 760px;
  line-height: 26px;
  word-break: keep-all;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
    margin-right: 25px;
    margin-top: 30px;
  }
`;

const AboutArticleSection = () => {
  return (
    <WhatAboutWrapper>
      <WhatAboutTitle>
        우리는 산업 전반의 오프라인 비즈니스를 온라인으로 전환시키고 이 과정에서
        성공적인 고객 경험 창출을 통해 시장을 개척합니다.
      </WhatAboutTitle>
      <WhatAboutText>
        오프라인의 제약을 벗어난 디지털 기술을 통해 고객들은 더욱 다양한 것을
        시도하고, 보다 나은 경험을 할 수 있습니다. 우리는 각 고객들에게 필요한
        기술을 공급하고, 그들에게 특화된 서비스를 제공합니다.
      </WhatAboutText>
    </WhatAboutWrapper>
  );
};

export default AboutArticleSection;
