import React from "react";
import styled from "styled-components";

const WhatWorkDoArticle = styled.li`
  text-align: center;
`;
const WhatWorkAppTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 11px;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 18px;
    font-weight: 400;
  }
`;
const WhatWorksubs = styled.p`
  width: 220px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 22px;
  word-break: keep-all;
`;

//work img
const WhatWorkImg = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 28px;
  margin-left: 10px;
`;

//work wrapper
const PerWork = styled.ul`
  display: flex;

  flex-direction: column;
  @media (min-width: 320px) and (max-width: 768px) {
    margin-bottom: 120px;
  }
`;

function WorkArticleItem({ src, title, subs }) {
  return (
    <PerWork>
      <WhatWorkImg src={src} />
      <WhatWorkDoArticle className="element-1">
        <WhatWorkAppTitle>{title}</WhatWorkAppTitle>
        <WhatWorksubs>{subs}</WhatWorksubs>
      </WhatWorkDoArticle>
    </PerWork>
  );
}

export default WorkArticleItem;
