import styled from 'styled-components';
import CultureListItem from 'components/CultureContent/CultureListItem';
import { DummyCulture } from 'constants/cultureArticleData';

const CultureListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: center;
  justify-content: space-between;
  width: 100%;
  height: 792px;
  margin-top: 90px;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    padding-top: 60px;
    margin: 10px 0;
  }
`;

function CultureListSection() {
  const articles = new DummyCulture().read();

  return (
    <CultureListWrapper>
      {articles.map((item) => (
        <CultureListItem key={item.id} {...item} />
      ))}
    </CultureListWrapper>
  );
}

export default CultureListSection;
