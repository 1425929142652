import React from 'react';
import styled from 'styled-components';
import HeaderNav from 'components/_common/Header';
import CeoContent from 'components/CeoContent';

const ScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

function Ceo() {
  return (
    <ScreenWrapper>
      <HeaderNav />
      <CeoContent />
    </ScreenWrapper>
  );
}

export default Ceo;
