import React from "react";
import styled from "styled-components";
import AboutHistoryItem from "components/AboutContent/AboutHistoryItem";
import { history, historyMobile } from "components/utils/getImages";
import { DummyAbout } from "constants/aboutDataClasses";

const WhatAboutHistoryWrapper = styled.section`
  max-width: 100vw;
  height: 2700px;
  background-image: url(${history});
  background-size: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  margin: 0 0 300px;
  @media (min-width: 320px) and (max-width: 768px) {
    background-image: url(${historyMobile});
    margin: 50px 0 100px;
    background-size: cover;
    height: 2100px;
    max-width: 100vw;
  }
`;

const HistoryDescriptionBack = styled.div`
  margin-top: 200px;
  margin: 0 360px;
  @media (min-width: 320px) and (max-width: 768px) {
    margin: 0 20px;
  }
`;

const HistoryDescription = styled.div``;

const HistoryDesTitle = styled.p`
  padding-top: 100px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
    padding-top: 125px;
  }
`;
const HistoryDesSubTitle = styled.p`
  margin-top: 20px;
  margin-bottom: 90px;
  font-size: 32px;
  font-weight: 500;
  line-height: 50px;
  width: 420px;
  color: #ffffff;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 20px;
    width: 253px;
    margin-bottom: 60px;
  }
`;

const HistoryWrapper = styled.ul``;

const History = styled.li`
  display: flex;
  flex-direction: row;
  margin-bottom: 90px;
  @media (min-width: 320px) and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 60px;
  }
`;
const Year = styled.p`
  font-size: 32px;
  font-weight: 500;
  margin-right: 133px;
  margin-top: 15px;
  color: #ffffff;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 14px;
  }
`;
const HistoryWrap = styled.div`
  margin-top: 5px;
`;

function AboutHistorySection() {
  const { historyStacks } = new DummyAbout().read();

  return (
    <WhatAboutHistoryWrapper>
      <HistoryDescriptionBack>
        <HistoryDescription>
          <HistoryDesTitle>History</HistoryDesTitle>
          <HistoryDesSubTitle>
            다양한 시각과 풍부한 경험으로 디자인을 설계합니다
          </HistoryDesSubTitle>
        </HistoryDescription>
        <HistoryWrapper>
          {historyStacks.map(({ year, stacks }) => (
            <History key={year}>
              <Year>{year}</Year>
              <HistoryWrap>
                {stacks.map((stack, index) => (
                  <AboutHistoryItem key={index} work={stack} />
                ))}
              </HistoryWrap>
            </History>
          ))}
        </HistoryWrapper>
      </HistoryDescriptionBack>
    </WhatAboutHistoryWrapper>
  );
}

export default AboutHistorySection;
