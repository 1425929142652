import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LinkList from 'components/_common/Header/LinkList';
import { mainLogo, closeWhite, toggleMenu } from 'components/utils/getImages';
import { routes } from 'routes';

const HeaderNav = styled.header``;

const HeaderNavWrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 9vh;
  padding: 0 35px;
  background-color: #fff;

  @media (min-width: 320px) and (max-width: 768px) {
    height: 60px;
    padding: 0;
    z-index: 5;
    padding: 0 20px;
  }
`;

const LogoIcon = styled.img`
  cursor: pointer;
  width: 162px;
  height: 22px;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 119px;
    height: 16px;
  }
`;

const CategoryBackground = styled.nav`
  @media (min-width: 320px) and (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: black;
    opacity: 0.8;
    display: ${({ menu }) => {
      return menu === false ? 'none' : 'inline-block';
    }};
  }
`;

const MenuIconContainer = styled.div`
  height: auto;
  display: none;
  cursor: pointer;
  @media (min-width: 320px) and (max-width: 768px) {
    display: inline-block;
    padding: 10px;
  }
`;

const MenuIconFixed = styled.div`
  position: fixed;
  height: auto;
  display: none;
  cursor: pointer;
  right: 20px;
  top: 10px;
  @media (min-width: 320px) and (max-width: 768px) {
    display: inline-block;
    padding: 10px;
  }
`;

const MenuIcon = styled.img`
  width: 12px;
  height: auto;
  @media (min-width: 320px) and (max-width: 768px) {
    height: 12px;
  }
`;

const LogoWrap = styled.h1`
  margin: 0;
  margin-left: 95px;
  @media (min-width: 320px) and (max-width: 768px) {
    margin-left: 0;
  }
  & a {
    display: flex;
    align-items: center;
  }
`;

const LogoTitle = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
`;

function Header() {
  const [menu, setMenu] = useState(false);

  const onToggle = useCallback(() => {
    setMenu((prevState) => !prevState);
  }, []);

  return (
    <HeaderNav>
      <HeaderNavWrapper>
        <LogoWrap>
          <Link to={routes.main}>
            <LogoIcon src={mainLogo} />
          </Link>
          <LogoTitle>successmode</LogoTitle>
        </LogoWrap>
        <MenuIconContainer>
          <MenuIcon onClick={onToggle} src={toggleMenu} />
        </MenuIconContainer>
        <CategoryBackground menu={menu}>
          <MenuIconFixed>
            <MenuIcon src={closeWhite} onClick={onToggle} />
          </MenuIconFixed>
          <LinkList />
        </CategoryBackground>
      </HeaderNavWrapper>
    </HeaderNav>
  );
}

export default Header;
