import styled from 'styled-components';

const Contacts = styled.div`
  @media (min-width: 320px) and (max-width: 768px) {
    margin-bottom: 64px;
  }
`;

const ContactsCity = styled.p`
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 26px;
  margin-top: 10px;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 20px;
  }
`;

const ContactsTitle = styled.p`
  font-size: 16px;
`;

const ContactsDes = styled.p`
  width: 310px;
  font-size: 14px;
  margin: 10px 0 30px 0;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 290px;
    margin: 10px 0 30px 0;
  }
`;

function ContactItem({ city, address, titletel, tel, email }) {
  return (
    <Contacts data-aos={'fade-right'}>
      <ContactsCity>{city}</ContactsCity>
      <ContactsTitle>Address</ContactsTitle>
      <ContactsDes>{address}</ContactsDes>
      <ContactsTitle>{titletel}</ContactsTitle>
      <ContactsDes>{tel}</ContactsDes>
      <ContactsTitle>Email</ContactsTitle>
      <ContactsDes>{email}</ContactsDes>
    </Contacts>
  );
}

export default ContactItem;
