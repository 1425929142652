import React from 'react';
import styled from 'styled-components';
import HeaderNav from 'components/_common/Header';
import Footer from 'components/_common/Footer';
import AboutContent from 'components/AboutContent';

const ScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

function About() {
  return (
    <>
      <ScreenWrapper>
        <HeaderNav />
        <AboutContent />
      </ScreenWrapper>
      <Footer />
    </>
  );
}

export default About;
