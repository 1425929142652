import React from 'react';
import styled from 'styled-components';
import HeaderNav from 'components/_common/Header';
import MainContent from 'components/MainContent';

const ScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

function Main() {
  return (
    <ScreenWrapper>
      <HeaderNav />
      <MainContent />
    </ScreenWrapper>
  );
}

export default Main;
