import styled from "styled-components";
import React, { useLocation } from "react-router-dom";

const WhatAboutMemberWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 320px) and (max-width: 768px) {
    flex-direction: column;
    align-items: baseline;
    width: 90vw;
  }
`;
const TeamImage = styled.img`
  width: 276px;
  height: auto;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 152px;
    height: atuo;
  }
`;
const TeamStack = styled.div`
  display: flex;
  flex-direction: column;
  width: 690px;
  margin-left: 85px;

  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;
const MemberName = styled.p`
  font-size: 32px;
  font-weight: 500;
  @media (min-width: 320px) and (max-width: 768px) {
    margin-top: 20px;
    font-size: 20px;
  }
`;
const MemberStack = styled.p`
  font-size: 14px;
  margin: 11px 0 20px 0;
`;
const MemberDes = styled.p`
  font-size: 14px;
  line-height: 24px;
  word-break: keep-all;
`;

const MemberSection = () => {
  const {
    state: { params },
  } = useLocation();

  const { src, name, stack, des } = params;

  return (
    <WhatAboutMemberWrapper>
      <TeamImage src={src} />
      <TeamStack>
        <MemberName>{name}</MemberName>
        <MemberStack>{stack}</MemberStack>
        <MemberDes>{des}</MemberDes>
      </TeamStack>
    </WhatAboutMemberWrapper>
  );
};

export default MemberSection;
