import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from 'routes';
import { ceoImgMain, line } from 'components/utils/getImages';
import AboutTeamItem from './AboutTeamItem';

const WhatAboutTeamWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 207px;
  @media (min-width: 320px) and (max-width: 768px) {
    margin-top: 125px;
  }
`;

const TeamDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 360px;
  @media (min-width: 320px) and (max-width: 768px) {
    margin: 0px 20px;
  }
`;

const TeamDesTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #5c5c5c;
`;

const TeamDesSubTitle = styled.p`
  margin-top: 20px;
  margin-bottom: 35px;
  font-size: 32px;
  font-weight: 500;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 20px;
  }
`;

const TeamDesSubDes = styled.p`
  font-size: 16px;
  margin: 0 0 10px;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
    line-height: 22px;
    margin: 0;
  }
`;

const TeamMemberWrapper = styled.div`
  width: 100%;
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  @media (min-width: 320px) and (max-width: 768px) {
    margin-top: 60px;
  }
`;

const TeamCeo = styled(Link)`
  display: flex;
  margin: 0 360px;
  cursor: pointer;
  @media (min-width: 320px) and (max-width: 768px) {
    margin: 0 20px;
  }
`;

const TeamMembers = styled.div`
  margin-top: 100px;
  @media (min-width: 320px) and (max-width: 768px) {
    margin-top: 80px;
  }
`;

const CeoImage = styled.img`
  width: 310px;
  height: auto;
  background-color: gray;
  &:hover {
    transform: scale(1.05);
    transition: linear 0.2s;
  }
  @media (min-width: 320px) and (max-width: 768px) {
    width: 180px;
  }
`;

const CeoImageWrap = styled.div`
  width: 300px;
  height: 440px;
  overflow: hidden;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 175px;
    height: 228px;
  }
`;

const DivideImg = styled.img`
  width: 100%;
  height: 82px;
  background-color: lightgray;
  @media (min-width: 320px) and (max-width: 768px) {
    height: 29px;
  }
`;

const CeoStacks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Name = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
    font-weight: 400;
  }
`;
const Stack = styled.p`
  font-size: 14px;
  font-weight: 400;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 12px;
  }
`;

const CeoStack = ({ name, stack }) => {
  return (
    <CeoStacks>
      <Name>{name}</Name>
      <Stack>{stack}</Stack>
    </CeoStacks>
  );
};

function AboutTeamSection() {
  return (
    <WhatAboutTeamWrapper>
      <TeamDescription>
        <TeamDesTitle>Our team</TeamDesTitle>
        <TeamDesSubTitle>작지만 강한 팀</TeamDesSubTitle>
        <TeamDesSubDes>
          x2 의 속도로 성장해 나갑니다. 다양한 경험과 맡은 바 전문성이 구성원과
          팀을 성장 시킵니다. 작지만 강한 팀.{' '}
        </TeamDesSubDes>
        <TeamDesSubDes>
          작지만 모든 걸 할 수 있는 팀이란 말이 어울립니다. 디자인 개발 마케팅에
          이르기까지 각자의 역할을 가지고 팀을 이끌어나갑니다.{' '}
        </TeamDesSubDes>
      </TeamDescription>
      <TeamMemberWrapper>
        <TeamCeo to={routes.ceo}>
          <CeoImageWrap>
            <CeoImage src={ceoImgMain} />
          </CeoImageWrap>
          <CeoStack name="Byeongcheon Ju" stack="CEO" />
        </TeamCeo>
        <DivideImg src={line} />
        <TeamMembers>
          <AboutTeamItem />
        </TeamMembers>
      </TeamMemberWrapper>
    </WhatAboutTeamWrapper>
  );
}

export default AboutTeamSection;
