import styled from 'styled-components';
import HeaderNav from 'components/_common/Header';
import MemberContent from 'components/MemberContent';

const ScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

function Member() {
  return (
    <ScreenWrapper>
      <HeaderNav />
      <MemberContent />
    </ScreenWrapper>
  );
}

export default Member;
