import styled from 'styled-components';
import WorkArticleSection from 'components/WorkContent/WorkArticleSection';
import WorkArticleItem from 'components/WorkContent/WorkArticleItem';
import { DummyWork } from 'constants/workDataClasses';

const WhatWorkContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 266px;
  margin: 0 360px;
  overflow-x: hidden;
  @media (min-width: 320px) and (max-width: 768px) {
    padding-top: 177px;
    margin: 0 20px;
  }
`;

const WhatWorkDos = styled.ul`
  padding-top: 90px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 340px;

  @media (min-width: 320px) and (max-width: 768px) {
    justify-content: center;
    margin-bottom: 0;
  }
`;

const WorkContent = () => {
  const workDos = new DummyWork().read();

  return (
    <WhatWorkContentWrapper>
      <WorkArticleSection />
      <WhatWorkDos>
        {workDos.map((work) => (
          <WorkArticleItem key={work.id} {...work} />
        ))}
      </WhatWorkDos>
    </WhatWorkContentWrapper>
  );
};

export default WorkContent;
