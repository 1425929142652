import styled from 'styled-components';
import CultureTopSection from 'components/CultureContent/CultureTopSection';
import CultureListSection from 'components/CultureContent/CultureListSection';
import CultureImageSection from 'components/CultureContent/CultureImageSection';

const CultureContentWrapper = styled.div`
  width: 1200px;
  height: 100%;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 320px;
  }
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CultureContent = () => {
  return (
    <Wrap>
      <CultureContentWrapper>
        <CultureTopSection />
        <CultureListSection />
        <CultureImageSection />
      </CultureContentWrapper>
    </Wrap>
  );
};

export default CultureContent;
