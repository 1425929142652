import React from "react";
import styled from "styled-components";

const HistoryStacks = styled.div`
  display: flex;
`;
const HistoryWork = styled.p`
  font-size: 16px;
  color: #ffffff;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

function AboutHistoryItem({ work }) {
  return (
    <HistoryStacks>
      <HistoryWork>{work}</HistoryWork>
    </HistoryStacks>
  );
}

export default AboutHistoryItem;
