import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 320px);
  align-content: center;
  justify-content: space-between;
  height: 138px;
  max-width: calc(100% - 720px);
  margin: 0 auto;

  @media (min-width: 320px) and (max-width: 768px) {
    grid-template-columns: repeat(3, 320px);
    grid-template-rows: repeat(3, 70px);
    grid-auto-flow: column;
    height: auto;
    margin: 200px 0 0 0;
    padding-left: 20px;
  }
`;

const FooterText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  word-break: keep-all;
  vertical-align: top;
  &:not(:last-child) {
    margin-right: 30px;
  }
  @media (min-width: 320px) and (max-width: 768px) {
    margin-bottom: 36px;
  }
`;

function Footer() {
  return (
    <FooterWrapper>
      <FooterText>© 2021 Success Mode</FooterText>
      <FooterText>
        +82. (0)2. 6275. 8011{'\n'}postmaster@successmode.co.kr{' '}
      </FooterText>
      <FooterText>
        8F 11-41, Simin-daero 327beon-gil, Dongan-gu, Anyang-si, Gyeonggi-do,
        Republic of Korea
      </FooterText>
    </FooterWrapper>
  );
}

export default Footer;
