import styled from 'styled-components';

const ArticleWrapper = styled.li`
  width: 48.5%;
  height: 148px;
  margin-bottom: 60px;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
`;

const ArticleTopNumber = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #5c5c5c;
  padding-bottom: 10px;
  border-bottom: 1px solid #c1c1c1;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
  }
`;

const ArticleTitleText = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 18px;
  }
`;

// ToDo: white-space: pre
const ArticleContentsText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  max-width: 430px;
  word-break: keep-all;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
  }
`;

function CultureListItem({ number, title, contents }) {
  return (
    <ArticleWrapper>
      <ArticleTopNumber>{number}</ArticleTopNumber>
      <ArticleTitleText>{title}</ArticleTitleText>
      <ArticleContentsText>{contents}</ArticleContentsText>
    </ArticleWrapper>
  );
}

export default CultureListItem;
