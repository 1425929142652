import styled from 'styled-components';

const WhatAboutServiceWrapper = styled.div`
  margin: 0 360px;
  margin-bottom: 345px;
  @media (min-width: 320px) and (max-width: 768px) {
    margin: 0 20px;
  }
`;
const ServiceDescription = styled.div``;
const ServiceTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #5c5c5c;
`;
const ServiceSubTitle = styled.p`
  margin-top: 20px;
  margin-bottom: 35px;
  font-size: 32px;
  font-weight: 500;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;
const ServiceSubDes = styled.p`
  font-size: 16px;
  margin: 0;
  line-height: 26px;
  word-break: keep-all;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 13px;
    margin-right: 0;
    line-height: 22px;
  }
`;
const ServiceList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  @media (min-width: 320px) and (max-width: 768px) {
    margin-top: 70px;
    margin-bottom: 0;
  }
`;
const Service = styled.p`
  font-size: 48px;
  font-weight: 500;
  margin: 0 0 15px;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 28px;
    font-weight: 500;
  }
`;

function AboutServiceSection() {
  return (
    <WhatAboutServiceWrapper>
      <ServiceDescription>
        <ServiceTitle>Our service</ServiceTitle>
        <ServiceSubTitle>우리가 할 수 있는 일에 집중합니다.</ServiceSubTitle>
        <ServiceSubDes>
          구성원 모두가 자기 분야에서 팀 리더로 활동하며, 자율적 결정권을 지니고
          그에 대한 책임을 집니다.
        </ServiceSubDes>
        <ServiceSubDes>
          작지만 큰 기능을 가지고 다양하지만 하나된 방향으로 향해 나아갑니다.
        </ServiceSubDes>
        <ServiceList>
          <Service>Strategy</Service>
          <Service>Digital Marketing</Service>
          <Service>Service Promotion</Service>
          <Service>PC / Mobile Web Design</Service>
          <Service>Graphic Design</Service>
          <Service>Development</Service>
        </ServiceList>
      </ServiceDescription>
    </WhatAboutServiceWrapper>
  );
}

export default AboutServiceSection;
