import { routes } from 'routes';

export class DummyNav {
  constructor() {
    this.data = [
      {
        id: 1,
        name: 'ABOUT',
        path: routes.about,
      },
      {
        id: 2,
        name: 'WORK',
        path: routes.work,
      },
      {
        id: 3,
        name: 'CULTURE',
        path: routes.culture,
      },
      {
        id: 4,
        name: 'CONTACT',
        path: routes.contact,
      },
    ];
  }
  read() {
    return this.data;
  }
}
