import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const ListItem = styled.li``;

const SNavLink = styled(NavLink)`
  color: #949494;
  border: none;
  background-color: transparent;
  font-size: 16px;
  cursor: pointer;

  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 32px;
    font-weight: 600;
    color: white;
    margin-bottom: 17px;
  }
`;

function LinkItem({ path, name }) {
  return (
    <ListItem>
      <SNavLink to={path} activeStyle={{ color: '#000' }}>
        {name}
      </SNavLink>
    </ListItem>
  );
}

export default LinkItem;
