import styled from 'styled-components';
import ContactArticle from 'components/ContactContent/ContactArticle';
import ContactMap from 'components/ContactContent/ContactMap';

const ContactWrapper = styled.div`
  margin-top: 85px;
  width: 90vw;
  @media (min-width: 320px) and (max-width: 768px) {
    margin-top: 65px;
  }
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ContactContent = () => {
  return (
    <Wrap>
      <ContactWrapper>
        <ContactArticle />
        <ContactMap />
      </ContactWrapper>
    </Wrap>
  );
};

export default ContactContent;
