import { MainArticle } from './Atoms';
import styled from 'styled-components';

const MainContentWrapper = styled.div`
  width: auto;
  padding: 0 90px;
  height: 100%;
  margin-bottom: 60px;
  @media (min-width: 320px) and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
`;

const MainContent = () => {
  return (
    <MainContentWrapper>
      <MainArticle />
    </MainContentWrapper>
  );
};

export default MainContent;
