import React from 'react';
import styled from 'styled-components';

const WhatAboutDoArticle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20vw;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

const WhatAboutDoNumber = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #5c5c5c;
`;

const WhatAboutDoBar = styled.div`
  margin-top: 11.5px;
  width: 378px;
  height: 1px;
  background-color: #c1c1c1;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 100%;
  }
`;
const WhatAboutDoTitle = styled.p`
  margin-top: 25.5px;
  font-size: 20px;
  font-weight: 600;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 18px;
  }
`;
const WhatAboutDoSubWrapper = styled.div`
  margin-top: 25px;
  @media (min-width: 320px) and (max-width: 768px) {
    margin-top: 0px;
  }
`;
const WhatAboutDoSubTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
`;
const WhatAboutdoSubDes = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-top: 13px;
  margin-bottom: 35px;
`;

const AboutDoSection = ({
  number,
  title,
  firstsubtitle,
  firstsubdes,
  secondsubtitle,
  secondsubdes,
}) => {
  return (
    <WhatAboutDoArticle>
      <WhatAboutDoNumber>{number}</WhatAboutDoNumber>
      <WhatAboutDoBar />
      <WhatAboutDoTitle>{title}</WhatAboutDoTitle>
      <WhatAboutDoSubWrapper>
        <WhatAboutDoSubTitle>{firstsubtitle}</WhatAboutDoSubTitle>
        <WhatAboutdoSubDes>{firstsubdes}</WhatAboutdoSubDes>
        <WhatAboutDoSubTitle>{secondsubtitle}</WhatAboutDoSubTitle>
        <WhatAboutdoSubDes>{secondsubdes}</WhatAboutdoSubDes>
      </WhatAboutDoSubWrapper>
    </WhatAboutDoArticle>
  );
};

export default AboutDoSection;
