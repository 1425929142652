import {
  aboutDos,
  historyStacks,
  profileData,
  ceoData,
} from 'constants/_dummy/dummyAbout';

export class DummyAbout {
  constructor() {
    this.aboutDos = aboutDos;
    this.profileData = profileData;
    this.historyStacks = historyStacks;
    this.ceoData = ceoData;
  }
  read() {
    return {
      aboutDos: this.aboutDos,
      profileData: this.profileData,
      historyStacks: this.historyStacks,
      ceoData: this.ceoData,
    };
  }
}
