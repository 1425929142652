import React from 'react';
import styled from 'styled-components';
import LinkItem from './LinkItem';
import { DummyNav } from 'constants/navDataClasses';

const LinkWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: right;
  width: 360px;
  margin-right: 95px;

  @media (min-width: 320px) and (max-width: 768px) {
    flex-direction: column;
    margin-left: 20px;
    transform: translate(0, 205%);
  }
`;

function LinkList() {
  const links = new DummyNav().read();

  return (
    <LinkWrapper>
      {links.map((link) => (
        <LinkItem key={link.id} {...link} />
      ))}
    </LinkWrapper>
  );
}

export default LinkList;
