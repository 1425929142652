import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { DummyAbout } from 'constants/aboutDataClasses';

const TeamStacks = styled.div``;

const MemberName = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-top: 27px;
  margin-bottom: 16px;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
  }
`;
const MemberStack = styled.p`
  font-size: 14px;
  font-weight: 400;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 12px;
  }
`;

const PerMemberWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  gap: 80px;
  padding: 100px;
  @media screen and (min-width: 320px) and (max-width: 768px) {
    justify-content: center;
  }
`;

const PerMember = styled(Link)`
  display: flex;
  justify-content: center;
  flex-basis: 30%;
`;

const TeamImage = styled.img`
  width: 276px;
  height: auto;
  &:hover {
    transform: scale(1.05);
    transition: linear 0.2s;
  }
  @media (min-width: 320px) and (max-width: 768px) {
    width: 152px;
    margin: 0;
  }
`;

const TeamImageWrap = styled.div`
  width: 260px;
  height: 325px;
  overflow: hidden;
  @media (min-width: 320px) and (max-width: 768px) {
    width: 142px;
    height: 172px;
  }
`;

function PerMembers() {
  const { profileData } = new DummyAbout().read();

  return (
    <PerMemberWrap>
      {profileData.map((item, index) => (
        <PerMember
          key={index}
          to={{ pathname: routes.member, state: { params: item } }}
        >
          <TeamStacks>
            <TeamImageWrap>
              <TeamImage src={item.src} />
            </TeamImageWrap>
            <MemberName>{item.name}</MemberName>
            <MemberStack>{item.stack}</MemberStack>
          </TeamStacks>
        </PerMember>
      ))}
    </PerMemberWrap>
  );
}

export default PerMembers;
