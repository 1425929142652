import React from 'react';
import styled from 'styled-components';

const WhatWorkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const WhatWorkTitle = styled.p`
  color: #212121;
  margin-right: 25%;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 400;
  word-break: keep-all;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 30px;
    margin-right: 10%;
    line-height: 42px;
  }
`;
const WhatWorkText = styled.p`
  font-size: 16px;
  line-height: 26px;
  margin: 0 0 5px;
  @media (min-width: 320px) and (max-width: 768px) {
    font-size: 14px;
    margin: 0;
  }
`;

function WorkArticleSection() {
  return (
    <WhatWorkWrapper className="element-1">
      <WhatWorkTitle>국내 관광쇼핑시장의 모든 것을 변화시킵니다.</WhatWorkTitle>
      <WhatWorkText>
        다가올 2000만 외국인 관광객 시대, 언어의 한계와 결제의 불편함을 디지털
        기술로 해소합니다.
      </WhatWorkText>
      <WhatWorkText>
        Post-COVID19 시점의 관광 모습은 달라질 것입니다. Untact 쇼핑을 구현하고
        여행의 본질에 집중할 수 있도록 고객들의 시간을 아껴줍니다.
      </WhatWorkText>
    </WhatWorkWrapper>
  );
}

export default WorkArticleSection;
